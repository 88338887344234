import * as React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';

const NotFoundPage: React.FC = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.500', 'gray.200');
  const buttonColor = useColorModeValue('blue.500', 'blue.300');
  const buttonHoverColor = useColorModeValue('blue.600', 'blue.400');

  return (
    <Box
      bg={bgColor}
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={6}
      py={12}
    >
      <Flex direction="column" align="center" maxW="sm" textAlign="center">
        <Box p={3} bg="blue.50" borderRadius="full">
          <Icon as={WarningIcon} w={6} h={6} color="blue.500" />
        </Box>
        <Heading mt={3} fontSize={{ base: '2xl', md: '3xl' }} color={textColor}>
          Ups, Seite nicht gefunden!
        </Heading>
        <Text mt={4} color={textColor}>
          Diese Seite ist wohl im Urlaub. Aber keine Sorge, hier sind ein paar
          nützliche Links:
        </Text>
        <Flex mt={6} w="full" gap={3} justify="center">
          <Button
            onClick={() => window.history.back()}
            leftIcon={<ArrowBackIcon />}
            variant="outline"
            colorScheme="gray"
            size="sm"
          >
            Zurück
          </Button>
          <Button
            as={Link}
            to="/"
            colorScheme="blue"
            bg={buttonColor}
            _hover={{ bg: buttonHoverColor }}
            size="sm"
          >
            Zur Startseite
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NotFoundPage;
